// src/context/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';
import { lightTheme, darkTheme } from './theme';

// Create the ThemeContext with default values
export const ThemeContext = createContext({
  theme: 'dark',
  toggleTheme: () => {},
});

const ThemeProvider = ({ children }) => {
  // Initialize theme state to a default value ('dark')
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    // This effect runs only on the client side
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) {
        setTheme(savedTheme);
      } else {
        // Optionally, set theme based on user's system preference
        const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        setTheme(prefersDark ? 'dark' : 'light');
      }
    }
  }, []);

  useEffect(() => {
    // Save theme preference to localStorage whenever it changes
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {/* Render children with the current theme */}
      {children(themeMode)}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
