// src/theme.js

export const lightTheme = {
  name: "light",
  colors: {
    background: '#FFFFFF', // White
    secondaryBackground: '#F0F0F0', // Light Gray
    primaryText: '#1C1C1C', // Very Dark Gray
    secondaryText: '#4D4D4D', // Dark Gray
    accent: '#1DB954', // Green
    accentHover: '#1ED760', // Lighter Green
    navActive: '#1DB954', // Green
    navIcon: '#707070', // Darker Gray for better contrast
    navHover: '#1ED760', // Lighter Green
    navHoverBackground: '#c0c0c0', // Darkened Light Gray for better contrast
    navFocusBackground: '#b0b0b0', // Darkened Light Gray for better contrast
    navActiveIndicator: '#1DB954', // Green
    navIconHover: '#1DB954', // Accent Green for better contrast
  },
  fonts: {
    main: "'Arial', sans-serif",
  },
  spacing: {
    sectionPadding: '80px 20px',
  },
};

export const darkTheme = {
  colors: {
    background: '#0d0d0d', // Very Dark Gray
    secondaryBackground: '#1a1a1a', // Dark Gray
    primaryText: '#FFF', // Light Gray
    secondaryText: '#FFF', // Lightened Gray for better contrast
    accent: '#347928', // Green
    accentHover: '#1ED760', // Lighter Green
    navActive: '#1DB954', // Green
    navIcon: '#B0B0B0', // Lightened Gray for better contrast
    navHover: '#1ED760', // Lighter Green
    navHoverBackground: '#1a1a1a', // Dark Gray
    navFocusBackground: '#2a2a2a', // Slightly Lighter Dark Gray
    navActiveIndicator: '#1DB954', // Green
  },
  fonts: {
    main: "'Arial', sans-serif",
  },
  spacing: {
    sectionPadding: '80px 20px',
  },
};
