// src/styles/global.js
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

// Import custom fonts

const GlobalStyle = createGlobalStyle`
  ${normalize}

  /* CSS Variables for theme colors and fonts */
  :root {
    --background: ${({ theme }) => theme.colors.background};
    --secondary-background: ${({ theme }) => theme.colors.secondaryBackground};
    --primary-text: ${({ theme }) => theme.colors.primaryText};
    --secondary-text: ${({ theme }) => theme.colors.secondaryText};
    --accent: ${({ theme }) => theme.colors.accent};
    --accent-hover: ${({ theme }) => theme.colors.accentHover};
    --nav-active: ${({ theme }) => theme.colors.navActive};
    --nav-icon: ${({ theme }) => theme.colors.navIcon};
    --nav-hover: ${({ theme }) => theme.colors.navHover};
    --nav-hover-background: ${({ theme }) => theme.colors.navHoverBackground};
    --nav-focus-background: ${({ theme }) => theme.colors.navFocusBackground};
    --nav-active-indicator: ${({ theme }) => theme.colors.navActiveIndicator};
    --nav-icon-hover: ${({ theme }) => theme.colors.navIconHover};
  
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 12px;
    display:none;
  }

  ::-webkit-scrollbar-track {
    background: var(--color-background-end);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 20px;
    border: 3px solid var(--color-background-end);
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: var(--background);
    color: var(--primary-text);
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--color-primary-text);
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: var(--color-secondary-text);
  }

  a {
    color: var(--color-accent);
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--color-accent-hover);
    }
  }

  img {
    max-width: 100%;
    display: block;
  }

  button {
    font-family: inherit;
    cursor: pointer;
    background: none;
    border: none;
  }

  /* Additional global styles */
  ul {
    list-style: none;
  }

  /* Utility classes */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
`;

export default GlobalStyle;
